<template>
    <div class="vip-layout">
        <v-container>
            <section>
                <div class="content">
                    <div class="title">
                        客戶分級設定
                    </div>
                    <div class="pl-8">
                        <div class="alert btn_graybg my-2 ">
                            <div class="alert_title">{{ groupName }}</div>
                            <v-col>
                                <v-btn class="btn btn_red" @click="toggleDeleteGroupPopup(true)">刪除分級</v-btn>
                            </v-col>
                        </div>
                    </div>

                    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
                    <template v-else>
                        <div class="d-flex align-center justify-space-between">
                            <v-checkbox class="custom-checkbox" v-model="selectedAll" label="全選"
                                        @change="selectAll"></v-checkbox>
                            <div class="d-flex">
                                <div class="mr-2" @click="handleDeleteAction">
                                    <v-btn small class="btn btn03" :disabled="selected.length === 0">
                                        <div class="add_title">刪除客戶</div>
                                    </v-btn>
                                </div>
                                <div @click="toggleAddStoreItemPopup(true)">
                                    <v-btn small class="btn btn01">
                                        <div class="add_title">新增客戶至{{ groupName }}</div>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-for="v in list" :key="v.store_id" class="d-flex">
                            <v-checkbox v-model="selected" :value="v.name"></v-checkbox>
                            <div class="alert alert2 my-2">
                                <div class="alert_title">{{ v.name }}</div>
                                <v-col>
                                    <v-btn class="alert2_icon" @click="toggleDeleteStorePopup(v.store_id, v.name)">
                                        <i aria-hidden="true"
                                           class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"></i>
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                    </template>
                </div>
                <v-row align="center" justify="center">
                    <v-col>
                        <v-btn @click="goBack" class="btn btn01" size="x-large">返回</v-btn>
                    </v-col>
                </v-row>
            </section>
        </v-container>
        <VipModal
                :show="addStoreItemPopup.switch"
                :title="`勾選客戶新增至${ groupName }`"
                @close="toggleAddStoreItemPopup(false)"
                @confirm="confirmAddStoreItemPopup"
        >
            <template>
                <v-card-text class="vip-layout">
                    <div class="d-flex align-center justify-space-between pl-5">
                        <v-checkbox class="custom-checkbox" v-model="addSelectedAll" label="全選"
                                    @change="addSelectAll"></v-checkbox>
                        <!-- <div class="d-flex">
                            <v-btn small dark class="btn btn02">
                                <div class="add_title">Excel匯入</div>
                            </v-btn>
                        </div> -->
                    </div>
                    <div v-for="v in storeList" :key="v.store_id" class="d-flex">
                        <div class="alert alert2">
                            <v-checkbox v-model="addStoreItemPopup.selectedItem" :value="v.store_id">{{ v.name }}</v-checkbox>
                            <div class="alert_title">{{ v.name }}</div>
                        </div>
                    </div>
                </v-card-text>
            </template>
        </VipModal>
        <VipModal
                :show="deleteGroupPopup.switch"
                :loading="deleteGroupPopup.loading"
                title="確定要刪除嗎？"
                @close="toggleDeleteGroupPopup(false)"
                @confirm="clickDeleteGroup"
        >
            <template>
                <v-card-text>
                    <p>刪除分級後，內容所做的設定都會刪除</p>
                </v-card-text>
            </template>
        </VipModal>
        <VipModal
                :show="updateGroupPopup.switch"
                :title="
                    `確定要將 <br><br>
                    ${updateGroupPopup.item.map(v => `${v.name}-${v.vipName}`).join('<br>')} <br><br>
                    移至此分級？
                    `"
                :loading="updateGroupPopup.loading"
                @confirm="clickUpdateGroup"
                @close="toggleUpdateGroupPopup(false)"
        >
            <template>
                <v-card-text>
                    <p>&nbsp;</p>
                </v-card-text>
            </template>
        </VipModal>
        <VipModal
                :show="confirmDeletePopup.switch"
                :title="`是否確定將「${confirmDeletePopup.storeName}」從${groupName}中移除？`"
                @confirm="clickDeleteStore(confirmDeletePopup.storeId)"
                @close="toggleConfirmDeletePopup(false)"
        >
            <template>
                <v-card-text>
                    <p>刪除後，此客戶的所有資訊將被永久移除。</p>
                </v-card-text>
            </template>
        </VipModal>
        <VipModal
                :show="confirmMultiDeletePopup.switch"
                :title="`是否確定將已勾選的客戶從${groupName}中移除？`"
                @confirm="confirmMultiDelete"
                @close="toggleMultiDeletePopup(false)"
        >
            <template>
                <v-card-text>
                    <p>刪除後，此客戶的所有資訊將被永久移除。</p>
                </v-card-text>
            </template>
        </VipModal>
    </div>
</template>

<script>
// import PrimaryModal from "components/modal/primaryModal.vue";
import VipModal from './vipModal.vue';

export default {
    components: {
        VipModal,
        // PrimaryModal,
    },
    data: () => ({
        loading: false,
        list: [],
        storeList: [],
        allStoreList: [],
        selected: [],
        selectedAll: false,
        addSelectedAll: false,
        deleteItemLoading: false,
        deleteGroupPopup: {
            switch: false,
            loading: false
        },
        addStoreItemPopup: {
            selectedItem: [],
            switch: false,
            loading: false
        },
        updateGroupPopup: {
            switch: false,
            loading: false,
            item: [] // name: '', vipName: ''
        },
        confirmDeletePopup: {
            switch: false,
            storeId: null,
            storeName: ''
        },
        confirmMultiDeletePopup: {
            switch: false,
        },
    }),
    computed: {
        providerId() {
            return this.$store.getters[`member/providerId`]
        },
        groupId() {
            return this.$route.params.id
        },
        groupName() {
            return this.$route.query.name;
        },
        vipList() {
            return JSON.parse(this.$route.query.list);
        },
    },
    watch: {
        'addStoreItemPopup.selectedItem': {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.addSelectedAll = newVal.length === this.storeList.length && this.storeList.length !== 0;
            },
        },
        selected(newVal) {
            this.selectedAll = newVal.length > 0 && newVal.length === this.list.length
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true
                const listResult = await this.$api.collection.vipApi.readStore(this.providerId, this.groupId)
                const listResultId = listResult.map(v => v.store_id)
                const filter = {status: 'cooperation'}
                const storeResult = await this.$api.collection.providerApi.clientIndex(this.providerId, {filter})
                this.allStoreList = storeResult.data
                this.storeList = storeResult.data.filter(v => !listResultId.includes(v.store_id))
                console.log('fetchData:', listResult, storeResult)
                this.list = listResult
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        },
        toggleDeleteGroupPopup(action) {
            this.deleteGroupPopup.switch = action
        },
        toggleAddStoreItemPopup(action) {
            this.addStoreItemPopup.switch = action
            // if (action && this.storeList.length > 0) {
            //     this.addStoreItemPopup.id = this.storeList[0].store_id
            // }
        },
        toggleUpdateGroupPopup(action) {
            this.updateGroupPopup.switch = action
        },
        async confirmAddStoreItemPopup() {
            this.$root.gaLogEvent('供應商_點擊_編輯客戶分級')
            const length = this.addStoreItemPopup.selectedItem.length
            if (length === 0 || this.storeList.length === 0) {
                this.toggleAddStoreItemPopup(false)
                return
            }
            this.updateGroupPopup.item = []
            for(let i = 0; i < length; i++){
                const id = this.addStoreItemPopup.selectedItem[i]
                try {
                    this.addStoreItemPopup.loading = true
                    const storeVipResult = await this.$api.collection.providerApi.readStoreVip(this.providerId, {store_id: [id]})
                    const storeVipObject = storeVipResult[0]
                    if (storeVipObject && this.vipList.some(v => v.id === storeVipObject.vip_group_id)) {
                        this.updateGroupPopup.item.push({
                            name: storeVipObject.name,
                            vipName: this.vipList.filter(v => v.id === storeVipObject.vip_group_id)[0].name
                        })
                    } else {
                        this.$root.gaLogEvent('供應商_點擊_歸屬客戶分級')
                        const result = await this.$api.collection.vipApi.addStore(this.providerId, id, {vip_group_id: this.groupId})
                        console.log('addData:', result)
                        await this.fetchData()
                        this.$snotify.success('新增成功')
                    }
                    this.toggleAddStoreItemPopup(false)
                } catch (error) {
                    console.error(error)
                    this.$snotify.error('新增失敗')
                } finally {
                    this.addStoreItemPopup.loading = false
                }
            }
            this.addStoreItemPopup.selectedItem = []

            if (this.updateGroupPopup.item.length > 0) {
                this.updateGroupPopup.switch = true
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        async clickDeleteGroup() {
            try {
                this.$root.gaLogEvent('供應商_點擊_刪除客戶分級')
                this.deleteGroupPopup.loading = true
                const result = await this.$api.collection.vipApi.delete(this.providerId, this.groupId)
                this.$snotify.success('刪除成功')
                this.goBack()
            } catch (error) {
                console.error(error)
                this.$snotify.error('刪除失敗')
            } finally {
                this.deleteGroupPopup.loading = false
            }
        },
        async clickDeleteStore(storeId) {
            if (this.deleteItemLoading) return
            try {
                this.deleteItemLoading = true
                const result = await this.$api.collection.vipApi.deleteStore(this.providerId, storeId, {vip_group_id: this.groupId})
                await this.fetchData()
                this.$snotify.success('刪除成功')
                this.toggleConfirmDeletePopup(false)
            } catch (error) {
                console.error(error)
                this.$snotify.error('刪除失敗')
            } finally {
                this.deleteItemLoading = false
            }
        },
        async clickUpdateGroup() {
            try {
                this.$root.gaLogEvent('供應商_點擊_歸屬客戶分級')
                this.updateGroupPopup.loading = true
                const selectedArr = this.allStoreList.filter(item => this.updateGroupPopup.item.map(v => v.name).includes(item.name))
                for (const selectedItem of selectedArr) {
                    await this.$api.collection.vipApi.updateStore(this.providerId, selectedItem.store_id, {vip_group_id: this.groupId})
                }
                await this.fetchData()
                this.$snotify.success('變更成功')
                this.toggleUpdateGroupPopup(false)
            } catch (error) {
                console.error(error)
                this.$snotify.error('變更失敗')
            } finally {
                this.updateGroupPopup.loading = false
            }
        },
        selectAll() {
            if (this.selectedAll) {
                // 如果已全選，則獲取所有客戶的名稱添加到 selected 數組中
                this.selected = this.list.map(item => item.name);
            } else {
                // 如果取消全選，則清空 selected 數組
                this.selected = [];
            }
        },
        toggleDeleteStorePopup(storeId, storeName) {
            this.confirmDeletePopup.storeId = storeId;
            this.confirmDeletePopup.storeName = storeName
            this.toggleConfirmDeletePopup(true);
        },
        toggleConfirmDeletePopup(action) {
            this.confirmDeletePopup.switch = action;
        },
        toggleMultiDeletePopup(action = true) {
            if (this.selected.length === 0) {
                return;
            }
            this.confirmMultiDeletePopup.switch = action;
        },
        async confirmMultiDelete() {
            // 實施刪除操作的邏輯
            // 注意：你需要根據你的API和數據結構進行適當的修改
            try {
                const selectedArr = this.list.filter(item => this.selected.includes(item.name))
                for (const selectedItem of selectedArr) {
                    await this.clickDeleteStore(selectedItem.store_id);
                }
                this.toggleMultiDeletePopup(false)
                this.selected = []
            } catch (error) {
                console.error(error);
                this.$snotify.error('批量刪除失敗');
            }
        },
        handleDeleteAction() {
            if (this.selected.length === 1) {
                // 如果只選中了一個客戶，獲取該客戶的詳細信息，然後顯示單獨的刪除彈窗
                const singleSelectedStore = this.list.find(item => item.name === this.selected[0]);
                this.toggleDeleteStorePopup(singleSelectedStore.store_id, singleSelectedStore.name);
            } else {
                this.toggleMultiDeletePopup();
            }
        },
        addSelectAll() {
            if (this.addSelectedAll) {
                this.addStoreItemPopup.selectedItem = this.storeList.map(item => item.store_id);
            } else {
                this.addStoreItemPopup.selectedItem = [];
            }
        },
    },
};
</script>
